import React, { useEffect, useRef, useState } from 'react';
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from 'react-map-gl';
import { makeGeoJSON, preparePoints, transformRequest } from './utils';
import { CommonPointsLayer, CustomPointsLayer } from './layers';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ExpandMore,
  Print,
  QrCode,
  Share,
  Layers as LayersIcon,
  Menu as MenuIcon,
  FileCopy,
} from '@mui/icons-material';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import ImageGallery from 'react-image-gallery';
import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf';
import 'react-image-gallery/styles/css/image-gallery.css';
import { CustomPopup } from './popup';
import Sidebar from './Sidebar';
import HomeIcon from '@mui/icons-material/Home';
import {
  IMAGES,
  INITIAL_VIEW_STATE,
  LOCALE,
  MAPBOX_SOURCE,
  MAPBOX_STYLE,
  MAPBOX_TOKEN,
  MAX_ZOOM,
  SHOW_CATEGORIES_CONTROL,
  SHOW_FULLSCREEN_CONTROL,
  SHOW_GEOLOCATE_CONTROL,
  SHOW_LAYERS_CONTROL,
  SHOW_NAVIGATE_CONTROL,
  SHOW_PRINT_CONTROL,
  SHOW_QR_CONTROL,
  SHOW_SCALE_CONTROL,
  SHOW_MOBILE_SLIDER,
  SHOW_SHARE_CONTROL,
  SHOW_SIDEBAR_CONTROL,
  SIDEBAR_INITIALLY_OPEN,
  SPREAD_SHEET_API,
} from './config';

function CustomMap() {
  const mapRef = useRef(null);
  const [points, setPoints] = useState(null);
  const [commonPoints, setCommonPoints] = useState(null);
  const [customPoints, setCustomPoints] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [layersVisible, setLayersVisible] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showSuccessCopy, setShowSuccessCopy] = useState(false);
  const [showSuccessQr, setShowSuccessQr] = useState(false);
  const url = generateUrlParams();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const INITIAL_VIEW_STATE = {
    latitude: parseFloat(process.env.REACT_APP_LATITUDE),
    longitude: parseFloat(process.env.REACT_APP_LONGITUDE),
    zoom: parseFloat(process.env.REACT_APP_ZOOM),
    bearing: parseFloat(process.env.REACT_APP_BEARING),
    pitch: parseFloat(process.env.REACT_APP_PITCH),
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window's height to state
      setWindowHeight(window.innerHeight);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window height
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const handleMarkerClick = (e) => {
    const [feature] = e.features;
    const { goToLatitude, goToLongitude } = feature.properties;
    const fly = goToLongitude && goToLatitude;
    setSelectedMarker((currentMarker) => {
      if (currentMarker === null && !fly) {
        return feature.properties;
      }
      return null;
    });
    if (fly) {
      goToLocation(feature.properties);
    }
  };

  function goToLocation(item) {
    const {
      longitude,
      goToBearing,
      latitude,
      goToZoom,
      goToLatitude,
      goToLongitude,
    } = item;
    mapRef.current?.flyTo({
      center: [goToLongitude || longitude, goToLatitude || latitude],
      duration: 2000,
      bearing: goToBearing || INITIAL_VIEW_STATE.bearing,
      zoom: goToZoom || MAX_ZOOM - 4,
    });
  }

  const onMapLoad = () => {
    const map = mapRef.current.getMap();

    IMAGES.forEach(([key, value]) => {
      map.loadImage(value, (error, image) => {
        if (error) throw error;
        console.log(key);
        map.addImage(key, image);
      });
    });

    const onMarkerEnter = () => (map.getCanvas().style.cursor = 'pointer');
    const onMarkerLeave = () => (map.getCanvas().style.cursor = '');

    // Attach events
    map.on('click', 'common', handleMarkerClick);
    map.on('click', 'custom', handleMarkerClick);
    map.on('mouseenter', 'common', onMarkerEnter);
    map.on('mouseleave', 'common', onMarkerLeave);
    map.on('mouseenter', 'custom', onMarkerEnter);
    map.on('mouseleave', 'custom', onMarkerLeave);
  };

  function getCheckedCategories(items) {
    return Object.entries(items)
      .map(([name, props]) => (props.open ? name : null))
      .filter((notNull) => notNull);
  }

  function filterByChecked(items) {
    return (point) =>
      items.includes(String(point.properties.category) || 'N/A');
  }

  function handleZoom(event) {
    if (!points) return;
    const { zoom } = event?.viewState;

    const filtered = { ...points };
    const categoriesChecked = getCheckedCategories(categories);

    filtered.features = filtered.features.filter(
      filterByChecked(categoriesChecked)
    );

    const filterResult = preparePoints(filtered, zoom);
    setCommonPoints(filterResult.commonPoints);
    setCustomPoints(filterResult.customPoints);
  }

  function handleShowGallery(value) {
    setShowGallery(value || true);
  }

  function handleCloseGallery() {
    setShowGallery(false);
  }

  // Full page
  function handlePrint() {
    const pdf = new jsPDF('l', 'mm', [210, 297]);
    const mapCanvas = mapRef?.current?.getMap().getCanvas();
    const imgData = mapCanvas.toDataURL('image/png');
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    pdf.addImage(imgData, 'PNG', 0, 0, width, height);
    pdf.save('map.pdf');
  }

  function generateUrlParams() {
    const mMap = mapRef?.current?.getMap();
    if (!mMap) return '';
    const centerCoords = mMap.getCenter();
    const urlParams = new URLSearchParams({
      lat: centerCoords.lat,
      lon: centerCoords.lng,
      zoom: mMap.getZoom(),
      bearing: mMap.getBearing(),
      pitch: mMap.getPitch(),
    });
    const [rootURL] = window.location.href.split('?');
    return `${rootURL}?${urlParams.toString()}`;
  }

  async function handleShare() {
    try {
      await navigator.clipboard.writeText(url);
      setShowSuccessCopy(true);
      setTimeout(() => setShowSuccessCopy(false), 2000);

      console.log('URL copied to clipboard: ' + url);
    } catch (error) {
      console.error('Error copying to clipboard ', error);
    }
  }

  async function handleQr() {
    try {
      const canvas = document.getElementById('qrCanvas');
      const link = document.createElement('a');
      const date = new Date();
      link.href = canvas.toDataURL('image/png');
      link.download = `${date}_qr.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowSuccessQr(true);
      setTimeout(() => setShowSuccessQr(false), 2000);
    } catch (error) {
      console.log('Error generating QR code ', error);
    }
  }

  const flyToInitialPosition = () => {
    const map = mapRef.current.getMap();
    map.flyTo({
      center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude],
      zoom: INITIAL_VIEW_STATE.zoom,
      bearing: INITIAL_VIEW_STATE.bearing,
      pitch: INITIAL_VIEW_STATE.pitch,
    });
  };

  const filterPointsByCategory = (
    categories,
    newCategory,
    isChecked,
    mapZoom
  ) => {
    const newCategories = {
      ...categories,
      [newCategory]: { ...categories[newCategory], open: isChecked },
    };

    const filtered = { ...points };
    const categoriesChecked = getCheckedCategories(newCategories).concat(
      isChecked ? [newCategory] : []
    );

    filtered.features = filtered.features.filter(
      filterByChecked(categoriesChecked)
    );

    const { commonPoints, customPoints } = preparePoints(filtered, mapZoom);

    return {
      newCategories,
      commonPoints,
      customPoints,
    };
  };

  function handleChangeCategory(event) {
    const map = mapRef.current.getMap();
    const { name, checked } = event.target;

    setCategories((prevCategories) => {
      const filterResult = filterPointsByCategory(
        prevCategories,
        name,
        checked,
        map.getZoom()
      );

      setCommonPoints(filterResult.commonPoints);
      setCustomPoints(filterResult.customPoints);

      return filterResult.newCategories;
    });
  }

  window.addEventListener('resize', IsOpenInMobileSlider);

  function IsOpenInMobileSlider() {
    const width = screen.width;
    if (width <= 600) setOpen(SHOW_MOBILE_SLIDER);
  }

  useEffect(() => {
    if (SIDEBAR_INITIALLY_OPEN) {
      setOpen(true);
      IsOpenInMobileSlider();
    }
  }, []);

  useEffect(() => {
    const loadMapData = async () => {
      try {
        const response = await fetch(SPREAD_SHEET_API);
        const jsonData = await response.json();
        const data = makeGeoJSON(jsonData);
        const { commonPoints, customPoints } = preparePoints(
          data,
          INITIAL_VIEW_STATE.zoom
        );
        setPoints(data);
        setCommonPoints(commonPoints);
        setCustomPoints(customPoints);

        const dataCategories = jsonData.reduce((acc, item) => {
          const category = item.category || 'N/A';

          if (acc[category]) {
            acc[category].count = acc[category].count + 1;
          } else {
            acc[category] = {};
            acc[category].count = 1;
            acc[category].open = true;
          }
          return acc;
        }, {});
        console.log({ dataCategories });
        setCategories(dataCategories);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    loadMapData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window?.location?.search);
    const latitude = parseFloat(params.get('lat'));
    const longitude = parseFloat(params.get('lon'));
    const zoom = parseFloat(params.get('zoom'));
    const pitch = parseFloat(params.get('pitch'));
    const bearing = parseFloat(params.get('bearing'));

    if (latitude && longitude) {
      INITIAL_VIEW_STATE.latitude = latitude;
      INITIAL_VIEW_STATE.longitude = longitude;
    }
    if (zoom) INITIAL_VIEW_STATE.zoom = zoom;
    if (pitch) INITIAL_VIEW_STATE.pitch = pitch;
    if (bearing) INITIAL_VIEW_STATE.bearing = bearing;
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: windowHeight,
      }}
    >
      <Sidebar
        data={points}
        open={open}
        loading={loading}
        goToLocation={goToLocation}
        handleShowGallery={handleShowGallery}
        setSelectedMarker={setSelectedMarker}
        handleClose={() => setOpen((prev) => !prev)}
      />
      <Map
        ref={mapRef}
        preserveDrawingBuffer={true}
        maxZoom={MAX_ZOOM}
        onZoomEnd={handleZoom}
        onLoad={onMapLoad}
        initialViewState={INITIAL_VIEW_STATE}
        transformRequest={transformRequest}
        locale={LOCALE}
        mapStyle={MAPBOX_STYLE}
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={['common', 'custom', 'mapbox-satellite']}
      >
        {!!selectedMarker && (
          <CustomPopup
            {...{
              selectedMarker,
              setSelectedMarker,
              handleShowGallery,
              showGallery,
            }}
          />
        )}
        {!!showGallery && (
          <Dialog
            open={showGallery}
            fullWidth={true}
            maxWidth={'xl'}
            onClose={handleCloseGallery}
          >
            <ImageGallery
              showBullets
              items={(typeof showGallery === 'boolean'
                ? selectedMarker
                : showGallery
              ).image
                .split(',')
                .map((url) => ({
                  original: url,
                }))}
            />
          </Dialog>
        )}
        {layersVisible && (
          <Source type="raster" url={MAPBOX_SOURCE}>
            <Layer
              {...{
                id: 'mapbox-satellite',
                source: 'mapbox-satellite',
                type: 'raster',
              }}
            />
          </Source>
        )}
        {commonPoints && (
          <Source type="geojson" data={commonPoints}>
            <Layer {...CommonPointsLayer} />
          </Source>
        )}
        {customPoints && (
          <Source type="geojson" data={customPoints}>
            <Layer {...CustomPointsLayer} />
          </Source>
        )}

        {SHOW_GEOLOCATE_CONTROL && (
          <GeolocateControl
            position="top-right"
            trackUserLocation
            showUserHeading
            positionOptions={{
              enableHighAccuracy: true,
            }}
          />
        )}
        {SHOW_FULLSCREEN_CONTROL && <FullscreenControl position="top-right" />}
        {SHOW_NAVIGATE_CONTROL && <NavigationControl position="top-right" />}
        {SHOW_SCALE_CONTROL && <ScaleControl />}
        {SHOW_PRINT_CONTROL && (
          <Paper
            sx={{
              padding: '4px',
              position: 'absolute',
              top: '110px', // Ajustado para colocarlo debajo del botón de compartir
              left: '8px',
              borderRadius: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={flyToInitialPosition} size={'small'}>
              <HomeIcon fontSize={'small'} />{' '}
              {/* Considera cambiar el ícono a algo más relevante, como un HomeIcon */}
            </IconButton>
          </Paper>
        )}

        {SHOW_SHARE_CONTROL && (
          <Paper
            sx={{
              padding: '4px',
              position: 'absolute',
              top: '60px',
              left: '8px',
              borderRadius: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isMobile ? (
              <HtmlTooltip
                SX={{ p: 0 }}
                position="left"
                open={showSuccessCopy}
                title={
                  <>
                    <TextField label="URL" variant="filled" value={url} />
                    <Button onClick={() => setShowSuccessCopy(false)}>
                      Close
                    </Button>
                  </>
                }
              >
                <IconButton
                  size={'small'}
                  onClick={() => setShowSuccessCopy(true)}
                >
                  <Share fontSize={'small'} />
                </IconButton>
              </HtmlTooltip>
            ) : (
              <Tooltip open={showSuccessCopy} title={'Link Copied'}>
                <IconButton onClick={handleShare} size={'small'}>
                  <Share fontSize={'small'} />
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        )}
        {SHOW_QR_CONTROL && (
          <Paper
            sx={{
              padding: '4px',
              position: 'absolute',
              top: '60px',
              left: '8px',
              borderRadius: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Tooltip
              open={showSuccessQr}
              title={'Descargado!'}
              placement="left"
            >
              <IconButton onClick={handleQr} size={'small'}>
                <QrCode fontSize={'small'} />
              </IconButton>
            </Tooltip>
            <QRCode
              id="qrCanvas"
              style={{ display: 'none' }}
              value={url}
              size={256}
            />
          </Paper>
        )}
        {SHOW_CATEGORIES_CONTROL && (
          <Box
            sx={{
              padding: '4px',
              position: 'absolute',
              top: '10px',
              right: '50px',
              borderRadius: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Categorías</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup>
                    {Object.entries(categories).map(([name, props]) => (
                      <FormControlLabel
                        key={name}
                        control={
                          <Checkbox
                            checked={props.open}
                            onChange={handleChangeCategory}
                            name={name}
                          />
                        }
                        label={name.charAt(0).toUpperCase() + name.slice(1)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
        {SHOW_LAYERS_CONTROL && (
          <Paper
            sx={{
              padding: '4px',
              position: 'absolute',
              top: '10px',
              left: '8px',
              borderRadius: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                setLayersVisible((prev) => !prev);
                setTimeout(() => {
                  const map = mapRef.current.getMap(); // Obtener el objeto del mapa
                  const layers = map.getStyle().layers; // Obtener todas las capas del estilo actual

                  // Encuentra la posición de la capa 'contour'
                  const contourIndex = layers.findIndex(
                    (layer) => layer.id === 'contour'
                  );

                  if (contourIndex !== -1) {
                    // Mueve la capa 'mapbox-satellite' justo por encima de la capa 'contour'
                    map.moveLayer(
                      'mapbox-satellite',
                      layers[contourIndex + 1].id
                    );
                  }
                }, 300);
              }}
              size="small"
            >
              <SatelliteAltIcon fontSize="small" />
            </IconButton>
          </Paper>
        )}
        {SHOW_SIDEBAR_CONTROL && (
          <Paper
            sx={{
              padding: '4px',
              position: 'absolute',
              top: '40px',
              left: '8px',
              borderRadius: '10%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={() => setOpen((prev) => !prev)} size={'small'}>
              <MenuIcon fontSize={'medium'} />
            </IconButton>
          </Paper>
        )}
      </Map>
    </Box>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default CustomMap;
