export const IMAGES = JSON.parse(process.env.REACT_APP_IMAGES);

export const SPREAD_SHEET_API = process.env.REACT_APP_SPREAD_SHEET_API;

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export const MAPBOX_STYLE = process.env.REACT_APP_MAPBOX_STYLE;

export const INITIAL_VIEW_STATE = {
  pitch: process.env.REACT_APP_PITCH,
  bearing: process.env.REACT_APP_BEARING,
  longitude: process.env.REACT_APP_LONGITUDE,
  latitude: process.env.REACT_APP_LATITUDE,
  zoom: process.env.REACT_APP_ZOOM,
};

export const MAX_ZOOM = process.env.REACT_APP_MAX_ZOOM;

export const LOCALE = {
  'NavigationControl.ResetBearing': 'Apuntar al norte',
  'FullscreenControl.Enter': 'Pantalla completa',
  'ScrollZoomBlocker.CtrlMessage': 'Para hacer zoom presione CTRL + Scroll',
  'ScrollZoomBlocker.CmdMessage': 'Para hacer zoom presione ⌘ + Scroll',
  'TouchPanBlocker.Message': 'Usa dos dedos para mover el mapa',
};

const {
  REACT_APP_SHOW_GEOLOCATE_CONTROL,
  REACT_APP_SHOW_FULLSCREEN_CONTROL,
  REACT_APP_SHOW_NAVIGATE_CONTROL,
  REACT_APP_SHOW_SCALE_CONTROL,
  REACT_APP_SHOW_CATEGORIES_CONTROL,
  REACT_APP_SHOW_SIDEBAR_CONTROL,
  REACT_APP_SHOW_LAYERS_CONTROL,
  REACT_APP_SIDEBAR_INITIALY_OPEN,
  REACT_APP_MAPBOX_SOURCE,
  REACT_APP_SLIDER_MOBILE,
  REACT_APP_SHOW_PRINT_CONTROL,
  REACT_APP_SHOW_SHARE_CONTROL,
  REACT_APP_SHOW_QR_CONTROL,
} = process.env;

export const SHOW_GEOLOCATE_CONTROL = JSON.parse(
  REACT_APP_SHOW_GEOLOCATE_CONTROL || 'false'
);
export const SHOW_FULLSCREEN_CONTROL = JSON.parse(
  REACT_APP_SHOW_FULLSCREEN_CONTROL || 'false'
);
export const SHOW_NAVIGATE_CONTROL = JSON.parse(
  REACT_APP_SHOW_NAVIGATE_CONTROL || 'false'
);
export const SHOW_SCALE_CONTROL = JSON.parse(
  REACT_APP_SHOW_SCALE_CONTROL || 'false'
);
export const SHOW_CATEGORIES_CONTROL = JSON.parse(
  REACT_APP_SHOW_CATEGORIES_CONTROL || 'false'
);
export const SHOW_QR_CONTROL = JSON.parse(REACT_APP_SHOW_QR_CONTROL || 'false');
export const SHOW_SIDEBAR_CONTROL = JSON.parse(
  REACT_APP_SHOW_SIDEBAR_CONTROL || 'false'
);
export const SHOW_LAYERS_CONTROL = JSON.parse(
  REACT_APP_SHOW_LAYERS_CONTROL || 'false'
);
export const SHOW_PRINT_CONTROL = JSON.parse(
  REACT_APP_SHOW_PRINT_CONTROL || 'false'
);
export const SHOW_SHARE_CONTROL = JSON.parse(
  REACT_APP_SHOW_SHARE_CONTROL || 'false'
);
export const SIDEBAR_INITIALLY_OPEN = JSON.parse(
  REACT_APP_SIDEBAR_INITIALY_OPEN || 'false'
);
export const SHOW_MOBILE_SLIDER = JSON.parse(
  REACT_APP_SLIDER_MOBILE || 'false'
);

export const MAPBOX_SOURCE = REACT_APP_MAPBOX_SOURCE;
