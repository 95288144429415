export function makeGeoJSON(data) {
  const features = [];
  const featureCollection = { type: 'FeatureCollection', features: features };

  // assuming data is an array of objects
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.longitude !== undefined && item.latitude !== undefined) {
      const lon = parseFloat(item.longitude);
      const lat = parseFloat(item.latitude);

      if (isNaN(lon) || isNaN(lat)) {
        console.log(
          'A row contained an invalid value for latitude or longitude'
        );
      } else {
        features.push({
          type: 'Feature',
          properties: {
            ...item,
            zoom: +item.zoom,
            iconSize: +item.iconSize,
            popupType: +item.popupType,
            iconImage: item.iconImage || '',
          },
          geometry: {
            type: 'Point',
            coordinates: [lon, lat],
          },
        });
      }
    }
  }
  return featureCollection;
}

export function transformRequest(url) {
  const isMapboxRequest =
    url.slice(8, 22) === 'api.mapbox.com' ||
    url.slice(10, 26) === 'tiles.mapbox.com';
  return {
    url: isMapboxRequest ? url.replace('?', '?pluginName=sheetMapper&') : url,
  };
}

export function processFeatures(data, zoom) {
  const zoomLevel = +zoom;
  console.log({ zoomLevel });
  return data.features.reduce(
    ({ common, custom }, feature, index) => {
      const {
        iconImage,
        disappearZoom,
        zoom: featureZoom,
      } = feature.properties;

      const checkDisappearZoom = disappearZoom
        ? zoomLevel <= disappearZoom
        : true;

      if (zoomLevel >= featureZoom && checkDisappearZoom) {
        if (iconImage) {
          custom.push(feature);
        } else {
          common.push(feature);
        }
      }

      return { common, custom };
    },
    {
      common: [],
      custom: [],
    }
  );
}

export function convertVhToPixels(vhValue) {
  return (vhValue / 100) * window.innerHeight;
}

export function preparePoints(data, zoom) {
  const { common, custom } = processFeatures(data, zoom);
  return {
    commonPoints: { type: 'FeatureCollection', features: common },
    customPoints: { type: 'FeatureCollection', features: custom },
  };
}
