export const CommonPointsLayer = {
  id: 'common',
  type: 'circle',
  paint: {
    'circle-radius': ['number', ['get', 'iconSize'], 5],
    'circle-color': [
      'coalesce',
      [
        'case',
        ['==', ['get', 'iconColor'], ''],
        'green', // Default color if the value is an empty string
        ['get', 'iconColor'],
      ],
      'green', // Default color if no value is present
    ],
    'circle-stroke-width': ['*', ['number', ['get', 'iconSize'], 5], 3], // Adjust border thickness.
    'circle-stroke-color': [
      'case',
      ['==', ['get', 'borderColor'], ''],
      ['get', 'iconColor'],
      ['get', 'borderColor'],
    ],
  },
};

export const CustomPointsLayer = {
  id: 'custom',
  type: 'symbol',
  layout: {
    'icon-image': ['get', 'iconImage'],
    'icon-size': ['number', ['get', 'iconSize'], 0.25],
    'icon-allow-overlap': true,
  },
};
