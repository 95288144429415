/**
 * @typedef {Object} ItemProperties
 * @property {string} category - The category of the item
 * @property {string} name - The name of the item
 * @property {string} description - The description of the item
 * @property {string} image - The URL of the item's image
 * @property {string} linkHTML - The URL of the page to open when the user clicks the button
 * @property {string} buttonText - The text of the button
 */

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from '@mui/material';
import { VariableSizeList } from 'react-window';
import { convertVhToPixels } from './utils';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import parse from 'html-react-parser';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NavigationIcon from '@mui/icons-material/Navigation';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const SIDEBAR_WIDTH = 320;
const ALLOWED_CATEGORIES = (process.env.REACT_APP_SHOW_CATEGORIES || '')
  .split(',')
  .filter((notNull) => notNull);
const TABS_CATEGORIES = JSON.parse(process.env.REACT_APP_SIDEBAR_TABS || '[]');
const SHOW_SIDEBAR_GALLERY = JSON.parse(
  process.env.REACT_APP_SIDEBAR_GALLERY || 'false'
);
const SIDEBAR_INITIALLY_OPEN = JSON.parse(
  process.env.REACT_APP_SIDEBAR_INITIALY_OPEN || 'false'
);
const SIDEBAR_LIST = JSON.parse(process.env.REACT_APP_SIDEBAR_LIST ?? '[]');

/**
 * Checks whether an item is allowed based on its category.
 *
 * @param {Object} item - The item object
 * @return {boolean} - Whether the item is allowed
 */
const itemAllowed = (item) =>
  ALLOWED_CATEGORIES.includes(item.properties.category);

/**
 * Sidebar component.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.open - Whether the sidebar is open
 * @param {Object} props.data - The list of items
 * @param {boolean} props.loading - Whether the data is still loading
 * @param {Function} props.handleClose - Function to handle closing the sidebar
 * @param {Function} props.goToLocation - Function to handle going to a location
 * @return {React.Node} The sidebar component
 */
function Sidebar({
  open,
  data,
  loading,
  handleShowGallery,
  handleClose,
  goToLocation,
}) {
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsByCategory, setFilteredItemsByCategory] = useState([]);
  const [value, setValue] = React.useState(SIDEBAR_LIST.length ? -1 : 0);
  const [isVisible, setVisibility] = useState(true);
  const [tabsIcons, setTabsIcons] = useState([]);
  const [searching, setSearching] = useState(false);
  const strategy = SIDEBAR_LIST.length ? 'vertical' : 'horizontal';
  const isHorizontalTabs = strategy === 'horizontal';
  const isVerticalTabsVisible = isVisible && strategy === 'vertical';

  useEffect(() => {
    setTabsIcons(
      SIDEBAR_LIST.map((item) => ({
        name: item.list[0],
        icon: item.icon,
      }))
    );
  }, []);

  useEffect(() => {
    console.log({ ALLOWED_CATEGORIES });
    const allowedByCategory = ALLOWED_CATEGORIES.length
      ? (data?.features || []).filter(itemAllowed)
      : data?.features || [];
    if (allowedByCategory) {
      setFilteredItemsByCategory(allowedByCategory);
      setFilteredItems(allowedByCategory);
    }
  }, [data?.features]);

  const handleChange = (event, newValue) => {
    setVisibility(!isVisible);
    setValue(newValue);
  };

  function handleSearch(search) {
    console.log({ search });
    const filtered = filteredItemsByCategory.filter((item) => {
      const name = (item.properties?.name || '').toLowerCase();
      // const category = item.properties.category.toLowerCase();
      // const description = item.properties.description.toLowerCase();
      if (item.properties?.name) {
        console.log(item.properties?.name);
      }
      return name.includes(search);
      // category.includes(search) ||
      // description.includes(search)
    });
    console.log({ filtered });
    setFilteredItems(filtered);
  }

  const Row = (items) =>
    function Item({ index, style }) {
      const item = items[index].properties;
      const images = item.image.split(',');
      const multi = images.length > 1;
      const [first] = images;
      const { height, ...rest } = style;
      return (
        <Card
          height={style.height}
          key={index}
          style={{ ...rest, maxWidth: SIDEBAR_WIDTH }}
          elevation={6}
        >
          {first && (
            <CardMedia
              sx={{ height: 140 }}
              image={first}
              title="Location Image"
            />
          )}
          {SHOW_SIDEBAR_GALLERY && multi && (
            <Button
              variant="text"
              endIcon={<CameraAltIcon />}
              sx={{
                position: 'absolute',
                left: 10,
                top: 12,
                color: 'white',
              }}
              onClick={() => handleShowGallery(item)}
            >
              {images.length}
            </Button>
          )}

          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {item.category}
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
              {parse(item.name)}
            </Typography>
            <Typography gutterBottom variant="body2">
              {parse(item.description)}
            </Typography>
          </CardContent>
          <CardActions>
            {item.buttonText && (
              <Button onClick={() => window.open(item.linkHTML, 'blank')}>
                {item.buttonText}
              </Button>
            )}
            <Button
              size="small"
              onClick={() => {
                goToLocation(item);
                handleClose();
              }}
            >
              Ubicar
            </Button>
          </CardActions>
        </Card>
      );
    };

  const Strategies = {
    horizontal: (category) =>
      TABS_CATEGORIES.find((tab) => tab.includes(category)),
    vertical: (category) =>
      SIDEBAR_LIST.find((tab) => tab.list.includes(category))?.list,
  };

  const showTabs = !!TABS_CATEGORIES?.length || SIDEBAR_LIST?.length;

  const categorization = showTabs
    ? filteredItems.reduce((acc, item) => {
        const { category } = item.properties;
        const found = Strategies[strategy](category);
        const key = found ? found[0] : 'otros';

        if (!acc[key]) acc[key] = [];

        acc[key].push(item);
        return acc;
      }, {})
    : null;

  const getIconTab = (category) => {
    const itemCategory = tabsIcons.filter((x) => x.name === category);

    const iconsTab = {
      locationonicon: <LocationOnIcon />,
      apartmenticon: <ApartmentIcon />,
      navigationicon: <NavigationIcon />,
    };

    return iconsTab[itemCategory[0]?.icon] ?? <NavigationIcon />;
  };

  return (
    <Drawer
      anchor={'left'}
      open={open}
      onClose={handleClose}
      variant={SIDEBAR_INITIALLY_OPEN ? 'persistent' : 'temporary'}
      className="container-primary"
    >
      <Box
        sx={{ width: SIDEBAR_WIDTH, overflow: 'hidden' }}
        role="presentation"
      >
        {SIDEBAR_INITIALLY_OPEN && (
          <IconButton
            size={'small'}
            onClick={handleClose}
            sx={{ position: 'absolute', top: -4, right: -6 }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {!loading && (
          <SearchBox handleSearch={handleSearch} setSearching={setSearching} />
        )}
        {!loading && searching && (
          <Typography variant="caption" sx={{ pl: 1 }}>
            {filteredItems.length} resultados encontrados
          </Typography>
        )}
        {loading && (
          <Box
            p={2}
            mt={2}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Cargando información...</Typography>
          </Box>
        )}
        {!loading && (!showTabs || searching) && (
          <VariableSizeList
            height={convertVhToPixels(100)}
            width={SIDEBAR_WIDTH}
            itemSize={getSize(filteredItems)}
            itemCount={filteredItems.length}
            overscanCount={5}
          >
            {Row(filteredItems)}
          </VariableSizeList>
        )}
        {!loading && categorization && !searching && (
          <>
            {isVerticalTabsVisible || isHorizontalTabs ? (
              <Tabs
                orientation={strategy}
                value={value}
                onChange={handleChange}
                aria-label="category tabs"
              >
                {Object.keys(categorization).map((category, index) => (
                  <Tab
                    className="tab-horizontal"
                    key={category}
                    label={category}
                    {...a11yProps(index)}
                    {...(isVerticalTabsVisible
                      ? { icon: getIconTab(category) }
                      : null)}
                  />
                ))}
              </Tabs>
            ) : (
              <IconButton
                sx={{ padding: '9px 3px' }}
                size={'small'}
                onClick={() => {
                  setVisibility((prev) => !prev);
                  setValue(-1);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {(!isVerticalTabsVisible || isHorizontalTabs) &&
              Object.entries(categorization).map(([category, items], index) =>
                +value === +index ? (
                  <VariableSizeList
                    height={convertVhToPixels(100)}
                    width={SIDEBAR_WIDTH}
                    itemSize={getSize(items)}
                    itemCount={items.length}
                    overscanCount={5}
                  >
                    {Row(items)}
                  </VariableSizeList>
                ) : null
              )}
          </>
        )}
      </Box>
    </Drawer>
  );
}

function getSize(items) {
  return (index) => (items[index].properties.image ? 380 : 200);
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function SearchBox({ handleSearch, setSearching }) {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = () => {
    setSearching(!!inputValue);
    handleSearch(inputValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
      event.preventDefault(); // Prevents page refresh
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: SIDEBAR_WIDTH - (SIDEBAR_INITIALLY_OPEN ? 26 : 0),
        ...(SIDEBAR_INITIALLY_OPEN
          ? {
              ml: 1,
              mt: 3,
            }
          : {}),
      }}
    >
      <InputBase
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={handleKeyPress}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Búscar"
        inputProps={{ 'aria-label': 'búscar' }}
      />
      <IconButton
        type="button"
        sx={{ p: '10px' }}
        aria-label="search"
        onClick={handleSubmit}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default Sidebar;
