import Map from './map/Map';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  Dialog,
  createTheme,
  CssBaseline,
  useMediaQuery,
  DialogActions,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import Box from '@mui/material/Box';

function container() {
  // Use the fullscreen element if in fullscreen mode, otherwise just the document's body
  return document.fullscreenElement ?? document.body;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
  },
  components: {
    MuiMenu: {
      defaultProps: {
        container,
      },
    },
    MuiDrawer: {
      defaultProps: {
        container,
      },
    },
    MuiModal: {
      defaultProps: {
        container,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          container,
        },
      },
    },
  },
});

const WELCOME_POPUP = process.env.REACT_APP_WELCOME_POPUP;

function App() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(true);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {WELCOME_POPUP && open && (
        <Dialog
          open={true}
          fullWidth={true}
          onClose={() => setOpen(false)}
          maxWidth={isMobile ? false : 'lg'}
        >
          <Box
            component="iframe"
            src={WELCOME_POPUP}
            sx={{ width: '100%', height: isMobile ? '100vh' : '80vh' }}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Continuar</Button>
          </DialogActions>
        </Dialog>
      )}
      <Map />
    </ThemeProvider>
  );
}

export default App;
