import {
  Dialog,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Popup } from 'react-map-gl';
import React, { useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';

const parseUrlParts = (url) => url.split('?');
const extractVideoId = (parts) => parts[1].split('&')[0].split('=')[1];
const extractTimestamp = (parts) => {
  const subParts = parts[1].split('&');
  if (subParts[1] && Number(subParts[1].split('=')[1].replace('s', ''))) {
    return subParts[1].split('=')[1].replace('s', '');
  }
  return false;
};
const constructUrl = (videoId, timestamp) =>
  `https://www.youtube.com/embed/${videoId}?${
    timestamp ? `start=${timestamp}&` : ''
  }autoplay=1`;

/*
  Popup 1: iframe 80% x 80%.
  Popup 2: Centrado con foto, Título, descripción y puede o no tener botón y galería, ver opción de que abre un iframe.
  Popup 3: Seguimiento tipo Treweler.
*/

export function CustomPopup({
  selectedMarker,
  setSelectedMarker,
  handleShowGallery,
}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  console.log({ selectedMarker });
  const handleClose = () => setSelectedMarker(null);

  if (selectedMarker.popupType === 1) {
    let urlModified = selectedMarker.linkHTML;

    if (urlModified.includes('youtube') && !urlModified.includes('embed')) {
      const parts = parseUrlParts(urlModified);
      const videoId = extractVideoId(parts);
      const timestamp = extractTimestamp(parts);
      urlModified = constructUrl(videoId, timestamp);
    }
    return (
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={isMobile ? false : 'xl'}
        fullScreen={isMobile}
        onClose={handleClose}
      >
        <Card sx={{ height: isMobile ? '100vh' : '80vh' }}>
          <IconButton
            sx={{ position: 'absolute', right: 10, top: 10, color: 'white' }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <CardMedia
            component="iframe"
            title={'popup-1'}
            allowFullScreen="true"
            src={urlModified}
            loading="lazy"
            sx={{ width: '100%', height: '100%' }}
          />
        </Card>
      </Dialog>
    );
  } else if (selectedMarker.popupType === 2) {
    return (
      <Dialog onClose={handleClose} open={true}>
        <SmallPopup
          {...{
            ...selectedMarker,
            setSelectedMarker,
            handleShowGallery,
            handleClose,
          }}
        />
      </Dialog>
    );
  } else if (selectedMarker.popupType === 3) {
    return (
      <Popup
        anchor="top"
        focusAfterOpen
        closeOnClick
        longitude={+selectedMarker.longitude}
        latitude={+selectedMarker.latitude}
        closeButton={false}
        style={{ width: 'auto', padding: 0 }}
        onClose={handleClose}
      >
        <SmallPopup
          {...{
            ...selectedMarker,
            setSelectedMarker,
            handleShowGallery,
            handleClose,
          }}
        />
      </Popup>
    );
  }
  return null;
}

const SmallPopup = (props) => {
  const {
    image,
    name,
    description,
    linkHTML,
    popupHTML,
    buttonText,
    handleClose,
    handleShowGallery,
  } = props;
  const images = image.split(',');
  const multi = images.length > 1;
  const [first] = images;
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && (
        <CustomPopup
          {...{
            selectedMarker: {
              popupType: 1,
              linkHTML: popupHTML,
            },
            setSelectedMarker: setOpen,
            handleShowGallery,
          }}
        />
      )}
      <Card sx={{ width: 'auto', height: 'auto' }} elevation={0}>
        <IconButton
          sx={{ position: 'absolute', right: 10, top: 10, color: 'white' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {first && <CardMedia component="img" src={first} alt="popup image" />}
        {multi && (
          <Button
            variant="text"
            endIcon={<CameraAltIcon />}
            sx={{
              position: 'absolute',
              left: 10,
              top: 12,
              color: 'white',
            }}
            onClick={handleShowGallery}
          >
            {images.length}
          </Button>
        )}
        <CardContent>
          <Typography variant="h5">{parse(name)}</Typography>
          <Typography>{parse(description)}</Typography>
        </CardContent>
        {buttonText && (
          <CardActions>
            <Button
              onClick={() => {
                if (popupHTML) {
                  setOpen(true);
                } else {
                  window.open(linkHTML, 'blank');
                }
              }}
            >
              {buttonText}
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};
